// ** Initial State
const initialState = {
    data: [],
    total: 1,
    params: {},
    isLoadingSessionAttendances: true
}

const adminSessionAttendances = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ADMIN_ATTENDANCE_LOADING_STATUS':
            return { ...state, isLoadingSessionAttendances: action.data }
        case 'GET_ADMIN_ATTENDANCE_SESSION_LIST':
            return {
                ...state,
                data: action.data,
                total: action.totalRecords,
                params: action.params,
                isLoadingSessionAttendances: false
            }
        default:
            return { ...state }
    }
}
export default adminSessionAttendances
